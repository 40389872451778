import React from "react";
import List from "../../components/list/list";
import projects from "../../data/projects";
import "./projects.css";

export default function Projects() {
  const reversedProjects = [...projects].reverse();
  return (
    <>
      <div className="projects-list">
        {/* <div className="project-card1">
          <div className="projectcard1-content">
            <h3>Mes créations</h3>
            <p>
              J'ai constamment cherché à améliorer mes compétences de
              développement, communication et d'écoute active, afin de mieux
              comprendre les besoins des utilisateurs et de fournir des
              solutions qui répondaient précisément à leurs attentes. Voici
              quelques projets que j'ai eu l'opportunété de réalisés.
            </p>
          </div>
        </div> */}

        <List projects={reversedProjects} />
      </div>
    </>
  );
}
